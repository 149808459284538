import Repository from '../services/repository.services'

const PREFIX = 'qhse/';

const state = {
    toolCertificatesArr: [],
    inspectorArr: [],
    personalCertificateArr: [],
    documentArr: [],
    employeeArr: [],
    employeeEPPArr: [],
    eppArr: [],
    toolItem: {},
    personalItem: {},
    coursesArr: [],
    success: false
}

const getters = {
    toolCertList: (state) => state.toolCertificatesArr,
    personalCertList: (state) => state.personalCertificateArr,
    inspectorList: (state) => state.inspectorArr,
    coursesList: (state) => state.coursesArr,
    documentList: (state) => state.documentArr,
    employeeList: (state) => state.employeeArr,
    employeeEPPList: (state) => state.employeeEPPArr,
    eppList: (state) => state.eppArr
}

const actions = {
    //CERTIFICADO DE HERRAMIENTAS
    getTools({ commit }){
        return Repository.get(`${PREFIX}tools_certificates`).then(            
            response => {
                commit('SET_LIST_TOOLS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getTool({ commit }, uuid){
        return Repository.get(`${PREFIX}tools_certificates/${uuid}`).then(            
            response => {
                commit('SET_TOOL', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    //CERTIFICADO DE PERSONAL
    getPersonalList({ commit }){
        return Repository.get(`${PREFIX}inspector`).then(            
            response => {
                commit('SET_LIST_PERSONAL', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    //
    getCertificates({ commit }, uuid){
        return Repository.get(`${PREFIX}personal_certificates/${uuid}`).then(            
            response => {
                commit('SET_CERTIFICATES', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    //CURSOS
    getCourses({ commit }){
        return Repository.get(`${PREFIX}courses`).then(            
            response => {
                commit('SET_LIST_COURSE', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    //EPP EMPLEADOS
    getEmployee({ commit }){
        return Repository.get(`${PREFIX}assign_epp/employee`).then(            
            response => {
                commit('SET_LIST_EMPLOYEE', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getEPPEmployee({ commit }, uuid){
        return Repository.get(`${PREFIX}assign_epp/${uuid}`).then(            
            response => {
                commit('SET_LIST_EPP_EMPLOYEE', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    getEpps({ commit }){
        return Repository.get(`${PREFIX}epp`).then(            
            response => {
                commit('SET_LIST_EPP', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    //DOCUMENTS
    getDocuments({ commit }, url){
        return Repository.get(`${PREFIX}documents/${url}`).then(            
            response => {
                commit('SET_DOCUMENT_LIST', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },

    clearDocumentList({ commit }){
        commit('CLEAR_DOCUMENT_LIST', []);
    },
    
    downloadFile({ commit }, data){
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(     
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve({data: response.data.data, status: response.status});
            },
            error => {
                return Promise.reject(error);
            }
        );
    },

    create({ commit }, data){
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(            
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    delete({ commit }, data){
        return Repository.delete(data.item, `${PREFIX}${data.entity}`).then(            
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    update({ commit }, data){
        return Repository.update(data.item, `${PREFIX}${data.entity}`).then(            
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
    uploadFile({ commit }, data){
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(            
            response => {
                commit('SET_SUCCESS', response);
                return Promise.resolve(response.status);
            },
            error => {
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST_TOOLS(state, res) {
        state.toolCertificatesArr = res.data.data;
    },   
    SET_TOOL(state, res) {
        state.toolItem = res.data.data;
    }, 
    SET_LIST_PERSONAL(state, res) {
        state.inspectorArr = res.data.data;
    },   
    SET_CERTIFICATES(state, res) {
        state.personalCertificateArr = res.data.data;
    }, 
    SET_LIST_COURSE(state, res) {
        state.coursesArr = res.data.data;
    },
    SET_LIST_EMPLOYEE(state, res){
        state.employeeArr = res.data.data;
    },  
    SET_LIST_EPP_EMPLOYEE(state, res){
        state.employeeEPPArr = res.data.data;
    }, 
    SET_LIST_EPP(state, res){
        state.eppArr = res.data.data;
    }, 
    SET_DOCUMENT_LIST(state, res) {
        state.documentArr = res.data.data;
    },   
    CLEAR_DOCUMENT_LIST(state, res) {
        state.documentArr = res;
    },  
    SET_SUCCESS(state, res) {
        state.success = res;
    },
}

export const qhse = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}