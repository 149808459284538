import AuthService from '../services/auth.services'
import getPermissions from '../../tools/permissions'
import getModules from '../../tools/modules'
//import location from '@/tools/geoposition'

const user_data = JSON.parse(localStorage.getItem('midgrab.app.stk'));

const state = {
    dataUser: user_data ? user_data : undefined,
    modules: user_data ? user_data.modules : [],
    success_message: null,
    logout_session: false,
    show_message: false,
    data_account_external: {},
    permissions: [],
    modules: [],
    user_profile: {},
    request_credentials: false,
}

const getters = {
    getActionSuccess: (state) => state.logout_session,
    getMyModules: (state) => state.modules,
    getDataUser: (state) => state.dataUser ? state.dataUser.data : null,
    getMessage: (state) => state.success_message,
    getVisibility: (state) => state.show_message,
    getDataAccountExternal: (state) => state.data_account_external,
    getProfile:  (state) => state.user_profile,
    getRequestSesion: (state) => state.request_credentials,
}

const actions = {    
    register({ commit }, user) {
        return AuthService.register_form(user).then(
            response => {
                return Promise.resolve(response.status);
            },
            error => {
                commit('REGISTER_FAILURE', error);
                return Promise.reject(false);
            }
        );
    },
    update({ commit }, user) {
        return AuthService.update_data(user).then(
            response => {
                return Promise.resolve(response.status);
            },
            error => {
                commit('REGISTER_FAILURE', error);
                return Promise.reject(false);
            }
        );
    },
    set_requestSesion({ commit }){
        commit('SET_VISIBILITY_REQUEST_SESION', true);
    },
    close_requestSesion({ commit }){
        commit('SET_VISIBILITY_REQUEST_SESION', false);
    },
    logout({ commit }) {
        commit('LOGOUT');
    },
    hidden_message({ commit }) {
        commit('HIDE_MESSAGE_SUCCES');
    },
    login_form({ commit }, data) {
        return AuthService.login_form(data).then(
            response => {
                commit('SET_LOGIN', response.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('FAIL_SET_LOGIN', error);
                return Promise.reject(false);
            }
        );
    },
    me({ commit }) {
        return AuthService.me().then(
            response => {
                commit('SET_PROFILE', response.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_PROFILE', error);
                return Promise.reject(false);
            }
        );
    },
    forgot_password({ commit }, user) {
        return AuthService.forgotPassword(user).then(
            response => {
                commit('SET_NOTIFY_SESSION', "SEND_CODE_FORGOT");
                return Promise.resolve(response.status);
            },
            error => {
                commit('REGISTER_FAILURE', error);
                return Promise.reject(false);
            }
        );
    },
    set_new_password({ commit }, user) {
        return AuthService.setPassword(user).then(
            response => {
                commit('SET_NOTIFY_SESSION', "PSW_CHANGED");
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_NOTIFY_SESSION', "ERR_PSW_CHANGE");
                return Promise.reject(error);
            }
        );
    },
    update_password({ commit }, user) {
        return AuthService.update_password(user).then(
            response => {
                commit('SET_NOTIFY_SESSION', "PSW_CHANGED");
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_NOTIFY_SESSION', "ERR_PSW_CHANGE");
                return Promise.reject(error);
            }
        );
    },
    notify_token_invalid({ commit }) {
        commit('SET_NOTIFY_SESSION', "INVALID");
    },
    notify_session_expired({ commit }) {
        commit('SET_NOTIFY_SESSION', "EXPIRED");
    },
    notify_not_session({ commit }) {
        commit('SET_NOTIFY_SESSION', "NO_SESSION");
    },
    notify_error_services({ commit }) {
        commit('SET_NOTIFY_SESSION', "NO_SESSION");
    },

}

const mutations = {
    LOGOUT(state) {
        state.loginSuccess = "Su sesión ha sido cerrada con éxito.";
        state.dataUser = undefined;
        localStorage.removeItem('midgrab.app.stk');
    },
    SET_VISIBILITY_REQUEST_SESION(state, visibility){
        state.request_credentials = visibility
    },
    SET_LOGIN(state, data) {
        
        state.dataUser = {
            data: data.user,
            acstapp: data.access_token
        };
        localStorage.setItem('midgrab.app.stk', JSON.stringify(state.dataUser));
        state.modules = data.user.modules;
        getPermissions();
        getModules();
        //location.tools.get_location();

    },
    SET_PROFILE(state, data){
        state.user_profile = data;
    },
    VERIFY_ACCONT_SUCCESS(state, rs) {
        if (rs.status == 200) {
            state.success_message = rs.data;
            state.show_message = true;
        }
    },
    HIDE_MESSAGE_SUCCES(state) {
        state.success_message = null;
        state.show_message = false;
    },
    FAIL_SET_LOGIN(state, error) {
        if (error.response) {
            state.success_message = error.response ? error.response.data.error : error.data.error;
            state.logout_session = false;
        }
        else if (error.data) {
            state.success_message = error.data.message;
        }

        state.show_message = true;

    },
    SET_NOTIFY_SESSION(state, success) {
        switch (success) {
            case "USER-CREATED":
                state.success_message = "El usuario fué creado con éxito.";
                state.show_message = true;
                state.logout_session = false;
                break;
            case "CREATED":
                state.success_message = "El registro fué creado con éxito.";
                state.show_message = true;
                state.logout_session = false;
                break;
            case "NOT_CREATED":
                state.success_message = "El registro no pudo ser creado, porfavor intente nuevamente o reportelo al administrador de sistemas.";
                state.show_message = true;
                state.logout_session = false;
                break;
            case "INVALID":
                state.success_message = "Su token es inválido.";
                state.show_message = true;
                state.logout_session = true;
                break;
            case "EXPIRED":
                state.success_message = "Su sesión ha expirado, favor de iniciar sesión nuevamente.";
                state.show_message = true;
                state.logout_session = true;
                break;
            case "NO_SESSION":
                state.success_message = "Es necesario iniciar sesión.";
                state.show_message = true;
                state.logout_session = true;
                break;
            case "VERIFICATED":
                state.success_message = "Su cuenta ha sido verificada con éxito.";
                state.show_message = true;
                state.logout_session = false;
                break;
            case "NO_VERIFICATED":
                state.success_message = "La cuenta no existe o ya fué verificada.";
                state.show_message = true;
                state.logout_session = false;
                break;
            case "SEND_CODE_FORGOT":
                state.success_message = "Se ha enviado un enlace a su correo para restablecer su contraseña.";
                state.show_message = true;
                state.logout_session = false;
                break;
            case "PSW_CHANGED":
                state.success_message = "La contraseña de su cuenta ha sido cambiada.";
                state.show_message = true;
                state.logout_session = true;
                break;
            case "ERR_PSW_CHANGE":
                state.success_message = "La contraseña no pudo ser cambiada, intente un nuevo proceso de recuperación.";
                state.show_message = true;
                state.logout_session = false;
                break;
            default:
                break;
        }

    },
    REGISTER_SUCCESS(state, response) {
        state.success_message = response.message;
        state.show_message = true;
    },
    REGISTER_FAILURE(state, error) {
        state.success_message = error.response.data.message;
        state.show_message = true;
    }
}

export const authenticate = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
