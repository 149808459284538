import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ViewingView from '../views/ViewingView.vue'
import Administracion from '../components/admin/AdminMain'
// import Administracion from '../components/admin/AdminHome'
import NotFound from '../views/NotFound.vue'
import store from '../store/store'
import axios from "axios";
import Unauthorized from '../views/Unauthorized.vue'
import Disabled from '../views/SuspendView.vue'
import validate from '../tools/validate.access'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Modulos Asignados',
    meta: { requiresAuth: true },
    component: Administracion,
    children:[
      {
        path: '',
        meta: { requiresAuth: true },
        name: 'Dashboard de Administracion',
        component: () => import('../components/admin/AdminHome')
      },
      {
        path: '/notificaciones',
        meta: { requiresAuth: false },
        name: 'Notificaciones',
        component: () => import('../components/auth/Notification/Notifications')
      },
      {
        path: 'usuarios',
        component: () => import('../components/admin/UserMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista de usuarios',
            component: () => import('../components/admin/UserTable')
          },
          {
            path: 'consultar/:uuid',
            meta: { requiresAuth: true },
            name: 'Consulta y edición de usuario',
            props: true,
            component: () => import('../components/admin/form/ReadUser')
          },
          {
            path: 'consultar/permisos/:uuid',
            meta: { requiresAuth: true },
            name: 'Permisos',
            props: true,
            component: () => import('../components/admin/form/ReadUserPermission')
          },
          {
            path: 'registro',
            meta: { requiresAuth: true },
            name: 'Registrar nuevo usuario',
            component: () => import('../components/admin/form/RegisterUser')
          },
        ]
      },
      {
        path: 'avisos',
        component: () => import('../components/admin/catalogs/Notices/NoticesTable')
      },
      {
        path: 'partidas',
        component: () => import('../components/admin/catalogs/Departure/DepartureTable')
      },
      {
        path: 'qhse_tools',
        component: () => import('../components/qhse/index'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista general',
            component: () => import('../components/qhse/ToolCertificate'),
          }
        ]
      },
      {
        path: 'qhse_certificate_personal',
        component: () => import('../components/qhse/index'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista general',
            component: () => import('../components/qhse/PersonalCertificate'),
          }
        ]
      }, 
      {
        path: 'qhse_epp_personal',
        component: () => import('../components/qhse/index'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista general',
            component: () => import('../components/qhse/Epp'),
          }
        ]
      },          
      {
        path: 'estado_de_cuenta',
        component: () => import('../components/AccountStatus/index'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista general',
            component: () => import('../components/AccountStatus/AccountStatusTable'),
          },
          {
            path: 'detalle/:client/:uuid',
            meta: { requiresAuth: true },
            name: 'Consulta',
            props: true,
            component: () => import('../components/AccountStatus/details'),
          },
        ]
      },
      {
        path: 'clientes',
        component: () => import('../components/admin/catalogs/Clientes/ClientsMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista de clientes',
            component: () => import('../components/admin/catalogs/Clientes/ClientsTable')
          },
          {
            path: 'consultar/:uuid',
            meta: { requiresAuth: true },
            name: 'Consulta y edición de clientes',
            props: true,
            component: () => import('../components/admin/form/ReadUser')
          },
          {
            path: 'registro',
            meta: { requiresAuth: true },
            name: 'Registrar nuevo cliente',
            component: () => import('../components/admin/catalogs/Clientes/tab/RegisterClient')
          },
          {
            path: 'editar/:uuid',
            meta: { requiresAuth: true },
            name: 'Editar cliente',
            props: true,
            component: () => import('../components/admin/catalogs/Clientes/tab/EditClient')
          },
        ]
      },
      {
        path: 'empleados',
        component: () => import('../components/admin/catalogs/Employee/EmployeeMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista de empleados',
            component: () => import('../components/admin/catalogs/Employee/EmployeeTable')
          },
          {
            path: 'registro',
            meta: { requiresAuth: true },
            name: 'Registrar nuevo empleado',
            component: () => import('../components/admin/catalogs/Employee/form/FormEmployee')
          },
          {
            path: 'editar/:uuid',
            meta: { requiresAuth: true },
            name: 'Editar empleado',
            props: true,
            component: () => import('../components/admin/catalogs/Employee/form/FormEmployeeEdit')
          },
        ]
      },
      {
        path: 'localizacion_sesiones',
        component: () => import('../components/locationSessions/locationSessionsTable'),
      },
      {
        path: 'creditos',
        meta: { requiresAuth: true },
        name: 'Lista de créditos',
        component: () => import('../components/admin/CreditsTable')
      },
      {
        path: 'solicitud_creditos',
        meta: { requiresAuth: true },
        name: 'Lista solicitud de créditos',
        component: () => import('../components/admin/CreditRequestTable')
      },
      {
        path: 'cotizaciones',
        component: () => import('../components/sales/SalesMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista de cotizaciones',
            component: () => import('../components/sales/SalesTable')
          },
          {
            path: 'registro',
            meta: { requiresAuth: true },
            name: 'Nueva cotización',
            component: () => import('../components/sales/CreateQuote.vue')
          },
          {
            path: 'consulta/:uuid/:action',
            meta: { requiresAuth: true },
            name: 'lectura/edición de cotización',
            props: true,
            component: () => import('../components/sales/ReadEditQuote.vue')
          },
          {
            path: 'consulta/:uuid/:action/:origin/:order',
            meta: { requiresAuth: true },
            name: 'accion desde pedidos',
            props: true,
            component: () => import('../components/sales/ReadEditQuote.vue')
          }
        ]
      },
      {
        path: 'logistica',
        meta: { requiresAuth: true },
        component: () => import('../components/logistics/index.vue'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Embarques',
            component: () => import('../components/logistics/ListView')
          },
        ]
      },
      // SEesta revisando 
      {
        path: 'reportes',
        meta: { requiresAuth: true },
        component: () => import('../components/reports/ReportMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Tabla de reportes',
            component: () => import('../components/reports/ReportTable')
          },
          {
            path: 'consultar/:reportuuid/:ideq/:action',
            name: 'Consulta de Reporte de Inspección',
            props: true,
            component: () => import('../components/reports/ReportFormRead')
          },
          {
            path: 'consultartt/:reportuuid/:ideq/:action',
            name: 'Consulta de Reporte de Inspección',
            props: true,
            component: () => import('../components/reports/ReportFormReadTT')
          },
          {
            path: 'consultartb/:reportuuid/:ideq/:action',
            name: 'Consulta de Reporte de Tuberia',
            props: true,
            component: () => import('../components/reports/ReportFormReadTB')
          },
          {
            path: 'registro/:equipment/:ideq/:action',
            name: 'Crear reporte de inspección',
            props: true,
            component: () => import('../components/reports/ReportFormCreate')
          },
          {
            path: 'clonar/:report/:idreq/:action',
            name: 'Copiar para nuevo reporte',
            props: true,
            component: () => import('../components/reports/ReportFormDuplicate')
          },
          {
            path: 'ordenservicio/:order/:idos/:os_number',
            name: 'Crear reporte de inspección en orden de servicio',
            props: true,
            component: () => import('../components/reports/ReportFormCreateOS')
          },
          {
            path: 'pruebatension/:order/:idos/:os_number',
            name: 'Crear reporte de inspección en orden de servicio',
            props: true,
            component: () => import('../components/reports/ReportFormCreateTT')
          },
          {
            path: 'tuberias/:order/:idos/:os_number',
            name: 'Crear reporte de inspección de tuberias',
            props: true,
            component: () => import('../components/reports/ReportFormCreatePL')
          },
          {
            path: 'edicion/:reportuuid/:ideq/:action',
            name: 'Editar reporte de inspección',
            props: true,
            component: () => import('../components/reports/ReportFormEdit')
          },
          {
            path: 'ediciontt/:reportuuid/:ideq/:action',
            name: 'Editar reporte de inspección',
            props: true,
            component: () => import('../components/reports/ReportFormEditTT')
          },
          {
            path: 'ediciontb/:reportuuid/:ideq/:action',
            name: 'Editar reporte de inspección',
            props: true,
            component: () => import('../components/reports/ReportFormEditPL')
          },
        ]
      },
      {
        path: 'certificados',
        component: () => import('../components/viewing/CertificatesTable')
      },
      {
        path: 'manufactura',
        component: () => import('../components/manufacture/ManufactureTable'),
      },
      {
        path: 'fabricacion',
        meta: { requiresAuth: true },
        component: () => import('../components/fabrication/FabricationMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista de reportes',
            component: () => import('../components/fabrication/FabricationTable')
          },
          {
            path: 'hoja-configuracion/:uuid/:action',
            name: 'Registro hoja de configuración',
            props: true,
            component: () => import('../components/fabrication/forms/FormCreateSheet')
          },
          {
            path: 'hoja-configuracion-editar-acero/:uuid/:action',
            name: 'Editar hoja de configuración',
            props: true,
            component: () => import('../components/fabrication/forms/FormSheetFormatOneEdit')
          },
          {
            path: 'hoja-configuracion-registro/:uuid/:action',
            name: 'Registro reporte de fabricación con hoja de configuración',
            props: true,
            component: () => import('../components/fabrication/forms/FormCreateSheetReport')
          },
          {
            path: 'hoja-configuracion-editar-poliester/:uuid/:action',
            name: 'Editar hoja de configuración',
            props: true,
            component: () => import('../components/fabrication/forms/FormSheetFormatTwoEdit')
          },
          {
            path: 'registro/:uuid/:action',
            name: 'Registro reporte de fabricación',
            props: true,
            component: () => import('../components/fabrication/forms/FormCreate')
          },
          {
            path: 'certificado/:uuid/:action',
            name: 'Consulta reporte de fabricación',
            props: true,
            component: () => import('../components/fabrication/forms/FormReadEdit')
          },
          {
            path: 'clonar/:uuid/:action',
            name: 'Clonar reporte de fabricación',
            props: true,
            component: () => import('../components/fabrication/forms/FormClone')
          },
        ]
      },
      {
        path: 'pedidos',
        component: () => import('../components/orders/OrdersTable'),
      },
      {
        path: 'facturacion',
        component: () => import('../components/invoicing/InvoicingTable'),
      },
      {
        path: 'nomina',
        meta: { requiresAuth: true },
        component: () => import('../components/accounting/paysheet/PaysheetMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista de nómina',
            component: () => import('../components/accounting/paysheet/PaysheetTable')
          },
          {
            path: 'registro',
            meta: { requiresAuth: true },
            name: 'Registrar nómina',
            component: () => import('../components/accounting/paysheet/form/FormPaySheet')
          },
          {
            path: 'editar/:uuid',
            meta: { requiresAuth: true },
            name: 'Editar nómina',
            props: true,
            component: () => import('../components/accounting/paysheet/form/FormPaySheetEdit')
          },
        ]
      },
      {
        path: 'compras',
        meta: { requiresAuth: true },
        component: () => import('../components/shopping/ShoppingMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista de Compras',
            component: () => import('../components/shopping/shopping/ShoppingTable')
          },
          {
            path: 'compras-registro',
            meta: { requiresAuth: true },
            name: 'Registrar nueva orden de compra',
            component: () => import('../components/shopping/shopping/form/FormOrders')
          },
          {
            path: 'compras-editar/:uuid',
            meta: { requiresAuth: true },
            name: 'Editar orden de compra',
            props: true,
            component: () => import('../components/shopping/shopping/form/FormOrdersEdit')
          },
          {
            path: 'proveedores',
            meta: { requiresAuth: true },
            name: 'Lista de Proveedores',
            component: () => import('../components/shopping/providers/ProvidersTable')
          },
          {
            path: 'proveedores-registro',
            meta: { requiresAuth: true },
            name: 'Registrar nuevo proveedor',
            component: () => import('../components/shopping/providers/tab/RegisterProviders')
          },
          {
            path: 'proveedores-editar/:uuid',
            meta: { requiresAuth: true },
            name: 'Editar proveedor',
            props: true,
            component: () => import('../components/shopping/providers/tab/EditProviders')
          },
          {
            path: 'proveedores-evaluaciones/:uuid',
            meta: { requiresAuth: true },
            name: 'Evaluaciones del proveedor',
            props: true,
            component: () => import('../components/shopping/providers/EvaluationsProviders')
          },
          {
            path: 'requisiciones',
            meta: { requiresAuth: true },
            name: 'Lista de requisiciones',
            component: () => import('../components/shopping/requisition/RequisitionTable')
          },
          {
            path: 'solicitud-registro',
            meta: { requiresAuth: true },
            name: 'Registrar nueva requisición',
            component: () => import('../components/shopping/requisition/form/FormRequest')
          },
          {
            path: 'solicitud-editar/:uuid',
            meta: { requiresAuth: true },
            name: 'Editar requisición',
            props: true,
            component: () => import('../components/shopping/requisition/form/FormRequestEdit')
          },
          {
            path: 'reportes',
            meta: { requiresAuth: true },
            name: 'Lista de Productos',
            component: () => import('../components/shopping/shopping/ProductReportTable')
          },
          {
            path: 'productos',
            meta: { requiresAuth: true },
            name: 'Lista de Productos',
            component: () => import('../components/shopping/products/ProductsTable')
          },
        ]
      },
      {
        path: 'almacen',
        meta: { requiresAuth: true },
        component: () => import('../components/store/StoreMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Lista de Inventario',
            component: () => import('../components/store/store/StoreTable')
            // StoreTable
          },
          {
            path: 'compras',
            meta: { requiresAuth: true },
            name: 'Lista de Compras',
            component: () => import('../components/store/shopping/ShoppingTable')
          },
          {
            path: 'salidas',
            meta: { requiresAuth: true },
            name: 'Lista de Salidas',
            component: () => import('../components/store/exit/ExitTable')
          },
        ]
      },
    ]
  },
  {
    path: '*',
    name: 'NotFound',
    meta: { requiresAuth: false },
    component: NotFound
  },
  {
    path: '/disabled',
    name: 'Disabled',
    meta: { requiresAuth: false },
    component: Disabled
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    meta: { requiresAuth: false },
    component: Unauthorized
  },
  {
    path: '/forgot_password',
    name: 'Restaurar contraseña',
    meta: { requiresAuth: false },
    component: () => import('../components/auth/Recovery')
  },
  {
    path: '/restore_pws/:code?/:hash?/:key?',
    name: 'Nueva contraseña',
    meta: { requiresAuth: false },
    props: true,
    component: () => import('../components/auth/NewPass')
  },
  {
    path: '/login',
    meta: { requiresAuth: false },
    name: 'Inicio de sesión',
    component: () => import('../components/auth/Login')
  },
  {
    path: '/logout',
    meta: { requiresAuth: false },
    name: 'Cerrar de sesión',
    component: () => import('../views/Logout')
  },
  {
    path: '/perfil',
    meta: { requiresAuth: false },
    name: 'Mi Perfil',
    component: () => import('../components/auth/Profile')
  },
  // {
  //   path: '/notificaciones',
  //   meta: { requiresAuth: false },
  //   name: 'Notificaciones',
  //   component: () => import('../components/auth/Notifications')
  // },
  {
    path: '/certificado',
    meta: { requiresAuth: false },
    name: 'certificado',
    component: () => import('../components/public/PublicMain'),
    children: [
      {
        path: 'manufactura/:uuid/:action/:id',
        name: 'Certificado de fabricación',
        meta: { requiresAuth: false },
        props: true,
        component: () => import('../components/fabrication/forms/DataSheet')
      }
      // {
      //   path: 'manufactura/:uuid/:action/:id/test',
      //   name: 'Certificado de fabricación',
      //   meta: { requiresAuth: false },
      //   props: true,
      //   component: () => import('../components/fabrication/forms/DataSheet')
      // }
    ]
  },

  {
    path: '/end',
    meta: { requiresAuth: false },
    name: 'end',
    component: () => import('../components/public/PublicMain'),
    children: [
      {
        path: ':selloDig/:uuid/:iddata/:client',
        name: 'Certificado de inspeccion',
        meta: { requiresAuth: false },
        props: true,
        component: () => import('../components/reports/Certificate')
      }
    ]
  },
  
  {
    path: '/administracion',
    meta: { requiresAuth: true },
    component: () => import('../components/admin/AdminMain'),
    children: [
      {
        path: 'reportes',
        meta: { requiresAuth: true },
        name: 'Metricas de Ventas',
        component: () => import('../components/admin/AdminReports')
      },
      {
        path: 'creditos/:uuid',
        meta: { requiresAuth: true },
        name: 'Consulta y edición de créditos',
        props: true,
        component: () => import('../components/admin/form/ReadCredit')
      },
      {
        path: 'solicitud_creditos/:uuid',
        meta: { requiresAuth: true },
        name: 'Consulta y edición de solicitudes para créditos',
        props: true,
        component: () => import('../components/admin/form/ReadCreditRequest')
      },
      {
        path: 'catalogos',
        component: () => import('../components/admin/CatalogMain'),
        children: [
          {
            path: '',
            meta: { requiresAuth: true },
            name: 'Productos y Servicios desde Excel',
            component: () => import('../components/admin/catalogs/CatProduct')
          }
        ]
      },
    ]
  },
  
];

const guard = function (to, from, next) {
  store.dispatch("configurations/setNavigateData", { to: to, from: from });
  axios
    .post(process.env.VUE_APP_SERVICES_HOST + "auth/me")
    .then((response) => {
      if (response) {
        next();
      }
      else {
        router.replace({ path: '/login' })
        store.dispatch("authenticate/notify_session_expired");
      }
    }, () => {
      router.replace({ path: '/login' })
      store.dispatch("authenticate/notify_session_expired");
    })
    .catch((e) => {
      
      if (e.data && e.data.error && e.data.error === "token is expired") {
        store.dispatch("authenticate/notify_session_expired");
      }
      router.replace({ path: '/login' })
    }
    );
};

function isAuthenticated() {
  let loggedIn = JSON.parse(localStorage.getItem('midgrab.app.stk'));
  if (loggedIn === null) {
    return null
  }
  else {
    return loggedIn.acstapp
  }
}

function isAutorized() {
  let loggedIn = JSON.parse(localStorage.getItem('midgrab.app.stk'));
  if (loggedIn && loggedIn.data && (loggedIn.data.rol_code == 'LrE127kuoAjQ' || loggedIn.data.rol_code == 'MIDAS02admin2' || loggedIn.data.rol_code == 'MIdGrAbMD03admin3')) {
    return true
  }
  else {
    return false
  }
}

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: "",
  linkActiveClass: "",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let isAuth = isAuthenticated()
    if (to.path != '/login' && isAuth) {
      if(isAuth == 'SUSPENDIDO'){
        router.replace({ path: '/disabled' })
      }
      else if (validate.credentials.canUserAccess(to.path)) {
        //next()
        guard(to, from, next)
      }
      else {
        router.replace({ path: '/unauthorized' })
      }

    }
    else {
      localStorage.removeItem('midgrab.app.stk');
      router.replace({ path: '/login' })
      store.dispatch("authenticate/notify_not_session");
    }
  }
  else {
    next()
  }
  // if (to.path == '/administracion' && isAutorized()){
  //   next();
  // }
  // else if (to.path == '/administracion' && !isAutorized()){
  //   store.dispatch("toasts/notify_unauthorized");
  // }
  // else if (to.matched.some((record) => record.meta.requiresAuth)) {
  //   if (to.path != '/login' && isAuthenticated()) {
  //     next();
  //   }
  //   else {
  //     localStorage.removeItem('midgrab.app.stk');
  //     router.push({ path: '/login' })
  //     store.dispatch("authenticate/notify_not_session");
  //   }
  // } else {
  //   next()
  // }
})

export default router
