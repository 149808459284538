

const credentials = {
    isAuthenticated: () => {
        let data = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        if (data) {
            return true
        }
        else {
            return false
        }
    },
    isAdmin: () => {
        let data = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        if (data && data.data && (data.data.rol_code == 'LrE127kuoAjQ' || data.data.rol_code == 'MIDAS02admin2'))
        // || data.data.rol_code == 'MIdGrAbMD03admin3')) 
        {
            return true
        }
        else {
            return false
        }
    },
    isMidgrab: () => {
        let data = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        if (data && data.data && (data.data.rol_code != 'FiXC9hcGlcbG')) {
            return true
        }
        else {
            return false
        }
    },
    isCompany: () => {
        let data = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        if (data && data.data && (data.data.rol_code == 'FiXC9hcGlcbG')) {
            return true
        }
        else {
            return false
        }
    },
    isSales: ()=>{
        let data = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        if (data && data.data && (data.data.rol_code == 'M5MDIyfQ')) {
            return true
        }
        else {
            return false
        }
    },

    canUserAccess: (to) => {
        let data = JSON.parse(localStorage.getItem('midgrab.app.stk'));
        let access = true;
        switch (to) {
            case '/administracion':
                if (data && data.data && (data.data.rol_code == 'LrE127kuoAjQ' || data.data.rol_code == 'MIDAS02admin2')) {
                    access = true
                }
                else {
                    access = false
                }
                break;
            case '/reportes':
                if (data && data.data && (data.data.rol_code != 'FiXC9hcGlcbG' && data.data.rol_code != 'MIdGrAbMD03admin3' && data.data.rol_code != 'M5MDIyfQ')) {
                    access = true
                }
                else {
                    access = false
                }
                break;
            case '/cotizaciones':
                if (data && data.data && (data.data.rol_code != 'FiXC9hcGlcbG' && data.data.rol_code != 'eyJpc3MicmFi')) {
                    access = true
                }
                else {
                    access = false
                }
                break;
            // case '/certificados':
            //     if (data && data.data && (data.data.rol_code == 'FiXC9hcGlcbG' || ata.data.rol_code == 'mid-QHS23')) {
            //         access = true
            //     }
            //     else if (data && data.data && (data.data.rol_code == 'LrE127kuoAjQ' || data.data.rol_code == 'MIDAS02admin2')) {
            //         access = true
            //     }
            //     else {
            //         access = false
            //     }
            //     break;
            // default:
            //     break;
        }
        return access;

    }
}

const rol = {
    isCompany: (data) => {
        if (data &&  data.rol_code == 'FiXC9hcGlcbG') {
            return true
        }
        else {
            return false
        }
    },
}

export default { credentials, rol }