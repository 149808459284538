import Repository from '../services/repository.services'
const PREFIX = 'accounting/';

const state = {
    clientList: [],
    accountList: [],
    invoicing: [],
    documents:[],
    comments:[],
    logs: [],
    order: [],
    quote: [],
    partial_remission: [],
    remission: [],
    executives_list: [],
    account_statement:[],
    accountStatementClient:[],
    statistics: {},
    success: false
}

const getters = {
    getClientList:(state) => state.clientList,
    getAccountStatesList:(state) => state.accountList,
    getInvoicing: (state) => state.invoicing,
    getDocuments: (state) => state.documents,
    getComments: (state) => state.comments,
    getLogs: (state) => state.logs,
    getExecutivesList: (state) => state.executives_list,
    getPDFOrder: (state) => state.order,
    getPDFQuote: (state) => state.quote,
    getPDFRemission: (state) => state.remission,
    getPartialRemission: (state) => state.partial_remission,
    getAccountStatus: (state) => state.account_statement,
    getStatistics: (state) => state.statistics,
    getAccountStatusClient: (state) => state.accountStatementClient
}
const actions = {
    getClients({ commit }) {
        return Repository.get(`${PREFIX}${process.env.VUE_APP_API_ENTITY_ALL_CLIENT}`).then(
            response => {
                commit('SET_LIST_CLIENTS', response.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST_CLIENTS', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getAccountStates({ commit }, data){
        return Repository.post(data, `${PREFIX}clients_statement`).then(
            response => {
                commit('SET_LIST_ACCOUNTSTATE', response.data);
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getStadisticList({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST_STADISTIC', { entity: entity, response: response.data });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_LIST_STADISTIC', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getListAccount({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LIST', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LIST', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    queryFilter({ commit }, data) {
        return Repository.post(data.item, `${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_LIST_ENTITY', { entity: data.entity, datos:response.data.data  });
                return Promise.resolve(response.status);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getUpload({ commit }, datos) {
        let item = datos.item;
        return Repository.upload(item,`${PREFIX}${datos.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
    getUpdate({ commit }, datos) {
        let item = datos.item;
        return Repository.update(item,`${PREFIX}${datos.entity}`).then(
        
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getPDF({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_PDF', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_PDF', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    getListC({ commit }, entity) {
        return Repository.get(`${PREFIX}${entity}`).then(
            response => {
                commit('SET_LISTC', { entity: entity, response: response.data });
                return Promise.resolve(response);
            },
            error => {
                commit('SET_LISTC', []);
                return Promise.reject(error);
            }
        ).catch((error) => {
            return Promise.reject(error);
        });
    },
    delete_entity({ commit }, data) {
        let item = data.item;
        return Repository.delete(item,`${PREFIX}${data.entity}`).then(
            response => {
                commit('SET_SUCCESS', true);
                return Promise.resolve(response);
            },
            error => {
                commit('SET_SUCCESS', false);
                return Promise.reject(error);
            }
        );
    },
}

const mutations = {
    SET_LIST_CLIENTS(state, res) {
        state.clientList = res.data;
    },
    SET_LIST_ACCOUNTSTATE(state, res) {
        state.accountList = res.data;
    },
    SET_LIST_STADISTIC(state, res){
        state.account_statement = res.response.data;
    },
    SET_LIST(state, res) {
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "invoicing":
                    state.invoicing = res.response.data;
                    if (res.response.executives) {
                        state.executives_list = res.response.executives;
                    }
                    break;
                case "invoicing_documents":
                    state.documents = res.response.data;
                    break;
                case "invoicing_comments":
                    state.comments = res.response.data;
                    break;
                case "invoicing_log":
                    state.logs = res.response.data;
                    break;
                case "invoicing_docorder":
                    state.order = res.response.data;
                    break;
                case "invoicing_docquote":
                    state.quote = res.response.data;
                    break;
                case "invoicing_partial_remision":
                    state.partial_remission = res.response.data;
                    break;
                case "account_statement":
                    console.log("datos: ", res.response)
                    state.account_statement = res.response.data;
                    state.statistics = res.response.statistics
                    if (res.response.executives) {
                        state.executives_list = res.response.executives;
                    }
                    break;
                // case "invoicing_partial_r":
                //     state.accountStatementClient = res.response.data;
                //     break;
            }
        }
    },
    SET_LIST_ENTITY(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "invoicing_filter":
                    state.invoicing = res.datos;
                    break;
                case "account_statement":
                    state.account_statement = res.datos;
                    break;
            }
        }
    },
    SET_LISTC(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "account_statement":
                    state.accountStatementClient = res.response.data;
                    if (res.response.executives) {
                        state.executives_list = res.response.executives;
                    }
                    break;
            }
        }
    },
    SET_SUCCESS(state, res) {
        state.success = res;
    },
    SET_PDF(state, res){
        if (res) {
            let val_entity = res.entity.toString().split("/");
            switch (val_entity[0]) {
                case "invoicing_docorder":
                    state.order = res.response.data;
                    break;
                case "invoicing_docquote":
                    state.quote = res.response;
                    break;
                case "invoicing_remision":
                    state.remission = res.response.data;
                    break;
            }
        }
    }
}

export const invoicing = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
